import { Transition } from 'react-transition-group';
import { reflow, getTransitionProps } from '@material-ui/core/transitions/utils';
import { useTheme, duration } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  entered: {
    opacity: 1,
    transform: 'translateY(0)'
  }
};

const defaultTimeout = {
  enter: duration.enteringScreen,
  exit: duration.leavingScreen,
};

function FadeUp(props) {
  const {
    children,
    in: inProp,
    onEnter,
    onExit,
    style,
    timeout = defaultTimeout,
    ...other
  } = props;
  const theme = useTheme();

  const handleEnter = (node, isAppearing) => {
    reflow(node); // So the animation always start from the start.
    const transitionProps = getTransitionProps(
      {style, timeout},
      {mode: 'enter'}
    );
    node.style.webkitTransition = theme.transitions.create(['opacity', 'transform'], transitionProps);
    node.style.transition = theme.transitions.create(['opacity', 'transform'], transitionProps);
    if (onEnter) {
      onEnter(node, isAppearing);
    }
  };

  const handleExit = node => {
    const transitionProps = getTransitionProps(
      {style, timeout},
      {mode: 'exit'}
    );
    node.style.webkitTransition = theme.transitions.create(['opacity', 'transform'], transitionProps);
    node.style.transition = theme.transitions.create(['opacity', 'transform'], transitionProps);
    if (onExit) {
      onExit(node);
    }
  };

  return (
    <Transition
      appear
      in={inProp}
      onEnter={handleEnter}
      onExit={handleExit}
      timeout={timeout}
      {...other}
    >
      {(state, childProps) => {
        return React.cloneElement(children, {
          style: {
            opacity: 0,
            transform: `translateY(${state === 'exiting' ? -8 : 8}px)`,
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            ...styles[state],
            ...style,
            ...children.props.style
          },
          ...childProps
        });
      }}
    </Transition>
  );
}

FadeUp.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  style: PropTypes.object,
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({enter: PropTypes.number, exit: PropTypes.number}),
  ]),
};

export default FadeUp;
