import { Box, Fab, makeStyles, Typography, useTheme, useMediaQuery, IconButton, createMuiTheme, ThemeProvider } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import GitHubIcon from '@material-ui/icons/GitHub';
import React, { useState } from 'react';
import ContactMeDialog from './ContactMeDialog';
import photo from './photo.jpg';
import TextCarousel from './TextCarousel';
import FadeUp from './FadeUp';
import StackOverflowIcon from './icons/StackOverflow';
import { indigo, grey } from '@material-ui/core/colors';

function Home() {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const appTheme = useTheme();
  const theme = createMuiTheme({
    ...appTheme,
    palette: {
      primary: indigo
    }
  });
  const classes = makeStyles({
    contactMeButtonIcon: {
      marginRight: theme.spacing(1)
    },
    portrait: {
      display: 'block',
      height: '100%',
      width: '100%'
    },
    root: {
      background: grey[200]
    }
  })();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box
        alignItems="center"
        className={classes.root}
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
        p={[0, 2]}
      >
        <FadeUp
          in={true}
          style={{flex: '0 0 auto'}}
        >
          <Box
            borderRadius="50%"
            boxShadow={8}
            height={160}
            overflow="hidden"
            width={160}
          >
            <img
              alt="Portrait of Simon Mathewson"
              className={classes.portrait}
              src={photo}
            />
          </Box>
        </FadeUp>
        <FadeUp
          in={true}
          style={{transitionDelay: '100ms'}}
        >
          <Box mt={2}>
            <Typography
              align="center"
              variant={isMobile ? 'h4' : 'h3'}
            >
              Simon Mathewson
            </Typography>
          </Box>
        </FadeUp>
        <FadeUp
          in={true}
          style={{transitionDelay: '200ms'}}
        >
          <Box
            mt={isMobile ? 1 : 1.5}
            width="100%"
          >
            <TextCarousel texts={['Full Stack Software Engineer']} />
          </Box>
        </FadeUp>
        <FadeUp
          in={true}
          style={{transitionDelay: '300ms'}}
        >
          <Box mt={3}>
            <ContactMeDialog
              isOpen={isContactDialogOpen}
              onClose={() => setIsContactDialogOpen(false)}
            />
            <Fab
              color="primary"
              onClick={() => setIsContactDialogOpen(true)}
              variant="extended"
            >
              <EmailOutlinedIcon className={classes.contactMeButtonIcon} />
              Contact Me
            </Fab>
          </Box>
        </FadeUp>
        <FadeUp
          in={true}
          style={{transitionDelay: '400ms'}}
        >
          <Box
            display="flex"
            justifyContent="center"
            mt={2}
          >
            <IconButton
              href="https://github.com/simon-mathewson"
              target="_blank"
            >
              <GitHubIcon />
            </IconButton>
            <IconButton
              href="https://stackoverflow.com/users/story/3873526"
              target="_blank"
            >
              <StackOverflowIcon />
            </IconButton>
          </Box>
        </FadeUp>
      </Box>
    </ThemeProvider>
  );
}

export default Home;
