import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, makeStyles, TextField, Slide, Snackbar } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import ContentCopyIcon from './icons/ContentCopy';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    width: '100%',
    '& button, & a': {
      width: '100%',
      '&:first-child': {
        borderTopLeftRadius: 0
      },
      '&:last-child': {
        borderTopRightRadius: 0
      }
    },
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  textField: {
    width: '100%',
    '& input': {
      paddingBottom: 14
    },
    '& fieldset': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 'none'
    }
  }
}));

const Transition = forwardRef((props, ref) => {
  return <Slide
    {...props}
    direction="up"
    ref={ref}
  />;
});

function ContactMeDialog(props) {
  const {onClose, isOpen} = props;
  const classes = useStyles();
  const email = 'info@simonmathewson.com';
  const [hasCopyFailed, setHasCopyFailed] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  function copyEmailToClipboard() {
    setHasCopyFailed(false);
    try {
      navigator.clipboard.writeText(email);
    } catch {
      setHasCopyFailed(true);
    }
    setIsSnackBarOpen(true);
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={isOpen}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Contact Me
      </DialogTitle>
      <DialogContent>
        <TextField
          className={classes.textField}
          defaultValue={email}
          disabled
          label="Email"
          variant="outlined"
        />
        <Box mb={2}>
          <ButtonGroup className={classes.buttonGroup}>
            <Button onClick={copyEmailToClipboard}>
              <ContentCopyIcon />
              Copy
            </Button>
            <Button
              href={'mailto:' + email}
              target="_blank"
            >
              <OpenInNewIcon />
              Open
            </Button>
          </ButtonGroup>
        </Box>
      </DialogContent>
      <Snackbar
        autoHideDuration={3000}
        message={hasCopyFailed ? 'Sorry, unable to copy automatically.' : 'Copied.'}
        onClose={() => setIsSnackBarOpen(false)}
        open={isSnackBarOpen}
      />
    </Dialog>
  );
}

ContactMeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ContactMeDialog;
