import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FadeUp from './FadeUp';
import React from 'react';
import { Box, Typography } from '@material-ui/core';

function TextCarousel(props) {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(
    () => {
      const timeoutId = setTimeout(() => {
        if (currentTextIndex < props.texts.length - 1) {
          setCurrentTextIndex(currentTextIndex + 1);
        } else {
          setCurrentTextIndex(0);
        }
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
    },
    [props.texts, currentTextIndex]
  )

  return (
    <Box
      position="relative"
      width="100%"
    >
      {props.texts.map((text, textIndex) => (
        <Box
          key={textIndex}
          position={textIndex === 0 ? 'relative' : 'absolute'}
          top={0}
          width="100%"
          zIndex={textIndex === currentTextIndex ? 1 : 0}
        >
          <FadeUp in={textIndex === currentTextIndex}>
            <Typography
              align="center"
              variant="h5"
            >
              {text}
            </Typography>
          </FadeUp>
        </Box>
      ))}
    </Box>
  );
}

TextCarousel.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default TextCarousel;
